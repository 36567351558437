<template>
    <div id="pageTable">
        <v-breadcrumbs :items="itemsLinks">
            <template v-slot:divider>
                <v-icon>chevron_right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-container grid-list-xl fluid>
            <v-row>
                <v-col>
                    <simple-table
                        :tableName="nombreTabla"
                        :isSelectable="showSelect"
                        :selected="selected"
                        @selectAllEvent="getSelected"
                        :rows="empleadosTabla"
                        :columns="columns"
                        :perPage="[10, 25, 50, 100]"
                        ref="tabla"
                        :loading="loading"
                    >   
                        <template slot="actionButtonsLeft">
                            <v-tooltip bottom  >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        :disabled="!btnEliminarMasivo"
                                        fab
                                        color="#FFFFFF"
                                        class="elevation-0 mr-3 btn-acciones"
                                        
                                        @click="eliminarMasivo()"
                                    >
                                        <v-icon color="#EB5757">clear</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar seleccionados</span>
                            </v-tooltip>
                            <div class="botonAgregar" style="padding-right: 12px;">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModalEmpleados('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" >
                                <td v-if="showSelect" style="width:150px">
                                    <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected"></v-checkbox>
                                </td> 
                                <td>
                                    <v-list color="transparent" class="py-0 my-0">
                                        <v-list-item class="py-0 my-0 px-0">
                                            <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                <img :src="urlAvatar + item.foto" alt="Empleado">
                                            </v-list-item-avatar>
                                            <v-list-item-content class="ml-0 py-0 my-0">
                                                <v-list-item-title class="list-item-titulo">{{item.nombre_completo }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </td>
                                <td > 
                                    <div>
                                        {{ item.evaluacion }} 
                                    </div>
                                </td>
                                <td > 
                                    <div>
                                        {{ item.observaciones }} 
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpcionesBtn">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="loadingModal" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row> 
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <p class="titleModal_600_15">Información general</p>
                                            </v-col>
                                            <p class="col-4 py-0 subtitle">Nombre del curso:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="detalleCapacitacion.nombre != null ? '':'text-mute'">{{detalleCapacitacion.nombre!= null ? detalleCapacitacion.nombre:"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Total de horas:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="detalleCapacitacion.total_horas != null ? '':'text-mute'">{{detalleCapacitacion.total_horas!= null ? parseInt(detalleCapacitacion.total_horas):"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha inicial:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="detalleCapacitacion.fecha_inicio != null ? '':'text-mute'">{{detalleCapacitacion.fecha_inicio!= null ? formatoFecha(detalleCapacitacion.fecha_inicio):"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha final:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="detalleCapacitacion.fecha_fin != null ? '':'text-mute'">{{detalleCapacitacion.fecha_fin!= null ? formatoFecha(detalleCapacitacion.fecha_fin):"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Nombre empleado:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="empleado.nombre_completo != null ? '':'text-mute'">{{empleado.nombre_completo != null ? empleado.nombre_completo:"Información faltante*"}}</span></p>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                    label="Evaluación" 
                                                    outlined 
                                                    v-model="empleado.evaluacion"  
                                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    v-on:keypress="presicionNumero($event,empleado.evaluacion)"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                <v-text-field type="text" label="Observaciones" outlined v-model="empleado.observaciones"  ></v-text-field>
                                            </v-col>
                                            
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    <v-btn class="btnGuardar" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
            <template class="borde-card">
                <v-dialog v-model="dialogEmpleados" persistent max-width="800px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalEmpleados"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="loadingModal" v-show="isLoadingModalEmpleados">
                                        <div id="loading">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>
                                    <v-container v-show="!isLoadingModalEmpleados" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row> 
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <p class="titleModal_600_15">Información general</p>
                                            </v-col>
                                            <p class="col-4 py-0 subtitle">Nombre del curso:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.nombre != null ? '':'text-mute'">{{cursoCapacitacion.nombre!= null ? cursoCapacitacion.nombre:"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Total de horas:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.total_horas != null ? '':'text-mute'">{{cursoCapacitacion.total_horas!= null ? cursoCapacitacion.total_horas:"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha inicial:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.fecha_inicio != null ? '':'text-mute'">{{cursoCapacitacion.fecha_inicio!= null ? formatoFecha(cursoCapacitacion.fecha_inicio):"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha final:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.fecha_fin != null ? '':'text-mute'">{{cursoCapacitacion.fecha_fin!= null ? formatoFecha(cursoCapacitacion.fecha_fin):"Información faltante*"}}</span></p>

                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    readonly
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    readonly
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <!-- <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Sucursal"
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="sucursal_value"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Departamento"
                                                    :items="departamentos"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="departamento_value"
                                                >
                                                </v-autocomplete>
                                            </v-col> -->
                                            <v-col cols="12" xs="11" sm="10" md="10" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empledos"
                                                    :items="empleados"
                                                    item-text="nombre_completo"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empleadosModel"
                                                    multiple
                                                    chips
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            ripple
                                                            @click="toggleAllEmpledos"
                                                        >
                                                            <v-list-item-action>
                                                                <v-icon :color="empleadosModel.length > 0 ? 'indigo darken-4' : ''">
                                                                    {{ icon }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    Seleccionar todos
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index === 0">
                                                            <span>{{ item.nombre_completo }}</span>
                                                        </v-chip>
                                                        <span
                                                            v-if="index === 1"
                                                            class="grey--text caption"
                                                        >
                                                            (+{{ empleadosModel.length - 1 }} otros)
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="1" sm="2" md="2" class="py-0">
                                                <v-btn color="#1E2245" class="mt-2" elevation="0" dark fab  @click="agregarEmpleados()" small>
                                                    <v-icon dark>add</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="empleadosLista.length > 0">
                                            <v-col cols="12" xs="12" sm="12" md="12">
                                                <h2 class="subtituloEmpleado mt-7 ml-2">Empleados</h2>
                                            </v-col>
                                            <template v-for="(i,index) in empleadosLista">
                                                <v-col cols="12" xs="12" sm="12" md="3" :key="i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field label="Empleado" outlined readonly type="text" :value="i.nombre_completo"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="5" md="2" :key="'H'+i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        label="Evaluación" 
                                                        outlined 
                                                        v-model="i.evaluacion"  
                                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-on:keypress="presicionNumero($event,i.evaluacion)"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="5" md="6" :key="'I'+i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field type="text" label="Observaciones" outlined v-model="i.observaciones"  ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" md="1" :key="'K'+i.id" class="py-2">
                                                    <v-btn class="botonHover" icon fab dark small @click="eliminarEmpleado(index, i.id)" >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    <v-btn class="btnGuardar" @click="guardarEmpleados()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import apiCursoCapacitacion from '@/api/cursoCapacitacion';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import departamentoApi from '@/api/departamentos';
import Notify from '../plugins/notify';
import DatatableSimple from '@/components/simpletableCheckbox/Datatable.vue';
import urlImg from '@/plugins/Urlimagen';

export default {
    components: {
        "simple-table": DatatableSimple,
    },
    data() {
        return {
            columns: [
                {
                    label : 'Nombre',
                    name  : 'nombre_completo'
                },
                {
                    label : 'Calificación',
                    name  : 'evaluacion'
                },
                {
                    label : 'Observaciones',
                    name  : 'observaciones',
                },
                {
                    label : 'Opciones',
                    name  : '',
                    align : 'center',
                },
            ],
            itemsLinks      : [
                {
                    text: "Capacitación",
                    disabled: false,
                    to: "/cursos_capacitaciones",
                },
                {
                    text: "Empleados",
                    disabled: true,
                    to: "/detalleCapacitacion",
                },
            ],
            cursoCapacitacion          : {
                id                      : null,
                cliente_id              : null,
                empresa_id              : null,
                nombre                  : null,
                fecha_inicio            : null,
                fecha_fin               : null,
                total_horas             : null
            },
            empleado            : {
                curso_id        : null,
                empleado_id     : null,
                evaluacion      : null,
                observaciones   : null,
                nombre_completo : null,
            },
            url             : "cursos/2",
            selected        : [],
            datosLogin      : null,
            rol             : null,
            filters         : {},
            detalleCapacitacion : null,
            nombreTabla     : "Capacitación",
            imagen          : "/static/modal/cursoCapacitacionEmpleados.svg",
            tituloModal     : "",
            isLoadingModal  : false,
            dialog          : false,
            dialogEmpleados : false,
            isSaving        : false,
            modalAgregarEmpleados : false,
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            departamento_value : null,
            isLoadingModalEmpleados : false,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            empleados       : [],
            empleadosModel  : [],
            empleadosLista  : [],
            empleadosTabla  : [],
            loading         : true,
            urlAvatar       : null,
            showSelect      : true,
            btnEliminarMasivo :  false,
        }
    },
    computed: {
        likesAllEmpleados () {
            return this.empleadosModel.length === this.empleados.length
        },
        likesSomeEmpleados () {
            return this.empleadosModel.length > 0 && !this.likesAllEmpleados
        },
        icon () {
            if (this.likesAllEmpleados) return 'mdi-close-box'
            if (this.likesSomeEmpleados) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        empresa_value: function(val, oldVal){
            if(val != null && this.modalAgregarEmpleados){
                this.cambiaEmpresa(val);
                this.getEmpleados();
            }
        },
        selected:function(value) {
            if(value.length > 0 ){
                this.btnEliminarMasivo =  true;
            }
            else{
                this.btnEliminarMasivo =  false;
            }
        }
    },
    methods: {
        /**
         * @method getSelected Este método que selecciona todos los emplados
         * @description Este método que selecciona todos los emplados.
        */
        getSelected(isSelected, items) {
            this.selected = items;
        },

        /**
         * @method abrirModal Este método abre el modal para actualizar el empleado
         * @description El método recibe los datos del empleado y se puede actulizar la caclificación y las observaciones del empleado
        */
        abrirModal(accion, item){
            this.tituloModal    = "Actualizar";
            this.imagen         = "/static/modal/cursoCapacitacionEmpleadosUpdate.svg";
            this.empleado       = {
                curso_id        : this.detalleCapacitacion.id,
                empleado_id     : item.id,
                evaluacion      : item.evaluacion,
                observaciones   : item.observaciones,
                nombre_completo : item.nombre_completo,
            }
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },


        /**
         * @method abrirModalEmpleados Este método abre el modal para asignar empleados a la Capacitación.
         * @description El método recibe los datos de la capacitación y abre un modal en el que se debe seleccionar empleados y agregarles
         * una evalución de 0 a 100 y unas observaciones.
        */
        abrirModalEmpleados(){
            this.tituloModal                    = "Empleados del curso";
            this.imagen                         = "/static/modal/cursoCapacitacionEmpleados.svg";
            this.modalAgregarEmpleados          = true;
            this.cursoCapacitacion.id           = this.detalleCapacitacion.id;
            this.cursoCapacitacion.cliente_id   = this.detalleCapacitacion.cliente_id;
            this.cursoCapacitacion.empresa_id   = this.detalleCapacitacion.empresa_id;
            this.cliente_value                  = this.detalleCapacitacion.cliente_id;
            this.empresa_value                  = this.detalleCapacitacion.empresa_id;
            this.cursoCapacitacion.nombre       = this.detalleCapacitacion.nombre;
            this.cursoCapacitacion.fecha_inicio = this.detalleCapacitacion.fecha_inicio;
            this.cursoCapacitacion.fecha_fin    = this.detalleCapacitacion.fecha_fin;
            this.cursoCapacitacion.total_horas  = parseFloat(this.detalleCapacitacion.total_horas);
            if(this.rol=='root'){
                this.getClientes();
            }

            this.isLoadingModalEmpleados = false;
            setTimeout(function(){ document.getElementById("btnModalEmpleados").click(); }, 100);
        },

        /**
         * @method eliminar Este método elimina un empleado.
         * @description Este método elimina un empleado y posteriormente actualiza la tabla
        */
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar empleado " + data.nombre_completo + "?",
                "Eliminar",
                () => {
                    let parametros = {curso_id: this.detalleCapacitacion.id, empleados: [{id:data.id}]};
                    apiCursoCapacitacion.deleteEmpleado(parametros).then(response => {
                        Notify.Success("Empleado eliminado", "El empleado ha sido eliminado satisfactoriamente.");
                        this.findEmpleados();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        /**
         * @method eliminarMasivo Este método elimina todos los empleados seleccionados en la tabla.
         * @description Este método elimina todos los empleados seleccionados en la tabla.
        */
        eliminarMasivo(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar los empleados seleccionados?",
                "Eliminar",
                () => {
                    let empleadosEliminar = [];
                    for(let empleado = 0; empleado < this.selected.length; empleado++){
                        let empleadoRegistro = { id : this.selected[empleado]}
                        empleadosEliminar.push(empleadoRegistro);
                    }
                    let parametros = {curso_id: this.detalleCapacitacion.id, empleados: empleadosEliminar};
                    apiCursoCapacitacion.deleteEmpleado(parametros).then(response => {
                        Notify.Success("Operación exitosa", "La operación se realizado satisfactoriamente.");
                        this.selected = [];
                        this.$refs.tabla.unselectAll();
                        this.findEmpleados();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        /**
         * @method guardar Este método actualiza el empleado seleccionado
         * @description Este métodoactualiza la calificación y las observaciones del empleado.
        */
        guardar(){
            this.isSaving = true;
            apiCursoCapacitacion.updateEmpleados(this.empleado).then(response => {
                Notify.Success("Operación exitosa", "El empleado ha sido actualizado satisfactoriamente.");
                this.findEmpleados();
                this.cerrarModal();
            })
            .catch(err => {
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        /**
         * @method cerrarModal Este método cierra los modales.
         * @description Este método cierra los modales y seguido realiza el llamado al metodo resetValues().
        */
        cerrarModal() {
            this.dialog     = false;
            this.dialogEmpleados = false;
            this.isSaving   = false;
            this.resetValues();
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método realiza el reseteo de los valores y que nos modales esten limpios al abrirlos. 
        */
        resetValues(){
            this.modalAgregarEmpleados  = false;
            this.sucursal_value         = null;
            this.departamento_value     = null;
            this.empleadosModel         = [];
            this.empleadosLista         = [];
            this.empleadosOriginal      = [];
            this.empleados              = [];

            this.$nextTick(() => {
                if(this.rol == 'root'){
                    this.cliente_value      = null;
                    this.empresa_value      = null;
                    this.empresas           = [];
                }

                if(this.rol == 'admin'){
                    this.empresa_value      = null;
                } 
            });

        },

        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes, el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición. 
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },

        /**
         * @method cambiaCliente Este método realiza petición a la API de empresas.
         * @description Realiza petición a la API de empresas cuando el cliente es seleccionada y el resultado lo almacena en el array empresas[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
         * @param {int} cliente_id ID del cliente seleccionado. 
        */
        cambiaCliente(id, cliente = null) {
            if(this.cursoCapacitacion.cliente_id != id){
                this.empresa_value = null;
                this.cursoCapacitacion.empresa_id = null;
            }
            
            if(id == null){
                return;
            }
            
            let parametros = { activo: true, paginate: false, cliente_id: id };

            empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast("No se pudo realizar la petición de clientes.");
            });
        },

        /**
         * @method cambiaEmpresa Este método realiza petición a la API de sucursales.
         * @description Realiza petición a la API de sucursales cuando la empresa es seleccionada y el resultado lo almacena en el array sucursales[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
         * @param {int} empresa_id ID de la empresa seleccionada. 
        */
        cambiaEmpresa(empresa_id) {
            let parametros = {activo: true, paginate: false, cliente_id: this.cliente_value, empresa_id : empresa_id};

            sucursalApi.getSucursales(parametros).then((response)=>{
                this.sucursales = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast("No se pudo realizar la petición de sucursales.");
            });
        },

        /**
         * @method cambiaSucursal Este método realiza petición a la API de departamentos.
         * @description Realiza petición a la API de departamentos cuando la sucursal es seleccionada y el resultado lo almacena en el array departamentos[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
         * @param {int} sucursal_id ID de la sucursal seleccionada. 
        */
        cambiaSucursal(sucursal_id) {
            let parametros = { activo: true, paginate: false, cliente_id: this.cliente_value, empresa_id : this.empresa_value, sucursal_id : sucursal_id };

            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
            })
            .catch(error => {
                this.resetValues();
                 Notify.ErrorToast("No se pudo realizar la petición de departamentos.");
            });
        },

        /**
         * @method getEmpleados Este método realiza petición a la API de empleados relacionados con la capacitación.
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let parametros = { 
                activo: true, 
                paginate: false,
                usuario_id : this.datosLogin.id,
                curso_id : this.cursoCapacitacion.id,
                cliente_id: this.cliente_value, 
                empresa_id : this.empresa_value, 
                sucursal_id : this.sucursal_value, 
                departamento_id : this.departamento_value,
            };

            apiCursoCapacitacion.getEmpleados(parametros).then((response)=>{
                let empleadosResponse = response.data;
                this.empleados = response.data;
                /* this.empleadosOriginal = empleadosResponse; */
            })
            .catch(error => {
                this.resetValues();
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        /**
         * @method toggleAllEmpledos Este método selecciona todos los empleados o los quita.
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        toggleAllEmpledos () {
            this.$nextTick(() => {
                if (this.likesAllEmpleados) {
                    this.empleadosModel = [];
                } else {
                    this.empleadosModel = [];
                    if(this.empleados.length > 0 ){
                        for (let empleado = 0; empleado < this.empleados.length; empleado++) {
                            this.empleadosModel.push(this.empleados[empleado].id);
                        }
                    }
                }
            })
        },

        /**
         * @method agregarEmpleados Este método para seleccionar todos los empleados.
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        agregarEmpleados(){
            if(this.empleadosModel.length > 0){
                if(this.empleadosLista.length > 0){
                    for(let empleado = 0; empleado < this.empleadosModel.length; empleado++){
                        let evaluarEmpleado = this.empleadosLista.find(element => element.id == this.empleadosModel[empleado]);
                        if(evaluarEmpleado == undefined){
                            let emp = this.empleados.find(element => element.id == this.empleadosModel[empleado]);
                            let empleadoRegistro = { 
                                id              : this.empleadosModel[empleado], 
                                evaluacion      : null, 
                                observaciones   : null,
                                nombre_completo : emp.nombre_completo, 
                            }
                            this.empleadosLista.push(empleadoRegistro);
                        }
                    }
                }else{
                    for(let empleado = 0; empleado < this.empleadosModel.length; empleado++){
                        let emp = this.empleados.find(element => element.id == this.empleadosModel[empleado]);
                        let empleadoRegistro = { 
                            id              : this.empleadosModel[empleado], 
                            evaluacion      : null, 
                            observaciones   : null,
                            nombre_completo : emp.nombre_completo, 
                        }
                        this.empleadosLista.push(empleadoRegistro);
                    }
                }                
            }
            else{
                Notify.ErrorToast("Seleccione al menos un empleado.");
            }
        },

        /**
         * @method guardarEmpleados Este método sirve para guardar la lista de guardar empleados, 
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        guardarEmpleados(){
            let self = this;
            this.isSaving = true;
            this.isLoading = true;
            
            let parametros = {curso_id: this.cursoCapacitacion.id, empleados: this.empleadosLista};
            apiCursoCapacitacion.storeEmpleados(parametros).then((response) => {
                Notify.Success("Operación exitosa", "Los registros se guardaron satisfactoriamente.");
                this.findEmpleados();
                this.cerrarModal();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            })
            .catch(err => {
                this.isSaving = false;
                this.isLoading = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        /**
         * @method eliminarEmpleado Este método sirve para guardar la lista de guardar empleados, 
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        eliminarEmpleado(index, idEmpleado){
            this.empleadosLista.splice(index,1);
        },


        /**
         * @method formatoFecha Esta método parsea una fecha
         * @description Convierte un fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se utiliza en el formateo de fecha del modal para agregar empleados.
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        formatoFecha(fecha){
            let fecha2 = fecha.split("-");
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
        },

        /**
         * @method presicionNumero Este método sirve para que la calificación nada mas acepte 3 decimales.
         * @description Este método sirve para que la calificación nada mas acepte 3 decimales.
         * @param event Evento del teclado.
         * @param {number} valor valor ingresado
        */
        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },

        /**
         * @method findEmpleados Este método sirve para listar los empleados en la tabla
         * @description Este método realiza una petición a la api de empleados que pueden asignarlos a curso y el resultado lo almacena en un array empleadosTabla[].
        */
        async findEmpleados(){
            this.loading = true;
            await apiCursoCapacitacion.findEmpleados(this.detalleCapacitacion.id).then((response) => {
                this.empleadosTabla = response.data;
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        }
    },
    async mounted() {
        await this.findEmpleados();
    },
    beforeCreate(){
        if(!this.$session.get('detalleCapacitacion')){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.urlAvatar = urlImg.getURL();
        this.detalleCapacitacion = this.$session.get("detalleCapacitacion");
        this.nombreTabla = this.detalleCapacitacion.nombre;
        this.url = "cursos/"+this.detalleCapacitacion.id;
    },
}
</script>

<style scoped>
#btnModalEmpleados {
    display: none;
}
.text-mute{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
}
.subtitle{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
}
.text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
}
.subtituloEmpleado{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E2245;
}
.btn-acciones{
    color: #FFFFFF;
    width: 42px;
    height: 42px;   
}
.btn-acciones:hover{
    color: #FFFFFF;
    width: 42px;
    height: 42px;   
}
</style>